import { useState } from "react";

import Footer from "partials/Footer";
import Button from "components/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/Modal";
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import Textarea from "components/form/Textarea";
import Switch from "components/form/Switch";
import Input from "components/form/Input";



const Accounts = () => {
  const [isSideModalActive, setIsSideModalActive] = useState(false);
  const [isCenteredModalActive, setIsCenteredModalActive] = useState(false);


  return (
    <main className="workspace">
      {/* Breadcrumb */}
      <section className="breadcrumb">
        <h1>Account Management</h1>
      </section>

      <div className="grid grid-cols-4 overflow-auto">
        <div className="card p-5  col-span-3">
          <div className="flow-root">
            <h3 className="float-left">All Accounts</h3>
            <a
              href="#no-link"
              className="btn btn_primary float-right -mt-1"
              onClick={() => setIsCenteredModalActive(true)}
            >
              New User
            </a>
          </div>
          <table className="table table_list mt-3 w-full">
            <thead>
              <tr>
                <th class="ltr:text-left rtl:text-right uppercase">Username</th>
                <th class="ltr:text-left rtl:text-right uppercase">
                  Full Name
                </th>
                <th class="ltr:text-left rtl:text-right uppercase">e-mail</th>
                <th class="ltr:text-left rtl:text-right uppercase">
                  Privileges
                </th>
                <th class="ltr:text-left rtl:text-right uppercase">Status</th>
              </tr>
            </thead>
            <tr>
              <td>
                <div class="flex-col">
                  <p>alberteinstein</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Albert Einstein</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>albert.einstein@theoryofrelativity.com</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Admin</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>
                    <span className="badge badge_outlined badge_success">
                      Active
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span
                    className="text-primary text-3xl leading-none la la-edit self-center"
                    onClick={() => setIsSideModalActive(true)}
                  ></span>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="flex-col">
                  <p>stephenhawking</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Stephen Hawking</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>stephen.hawking@blackholes.com</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>User</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>
                    <span className="badge badge_outlined badge_warning">
                      Inactive
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span
                    className="text-primary text-3xl leading-none la la-edit self-center"
                    onClick={() => setIsSideModalActive(true)}
                  ></span>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="flex-col">
                  <p>mariecurie</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Marie Curie</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>marie.curie@radiology.com</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>User</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>
                    <span className="badge badge_outlined badge_success">
                      Active
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span
                    className="text-primary text-3xl leading-none la la-edit self-center"
                    onClick={() => setIsSideModalActive(true)}
                  ></span>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="flex-col">
                  <p>issacnewton</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Isaac Newton</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>issac.newton@gravity.com</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>User</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>
                    <span className="badge badge_outlined badge_success">
                      Active
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span
                    className="text-primary text-3xl leading-none la la-edit self-center"
                    onClick={() => setIsSideModalActive(true)}
                  ></span>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="flex-col">
                  <p>galileo</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>Galileo Galilei</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>galileo.galilei@telescope.com</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>User</p>
                </div>
              </td>
              <td>
                <div class="flex-col">
                  <p>
                    <span className="badge badge_outlined badge_success">
                      Active
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span
                    className="text-primary text-3xl leading-none la la-edit self-center"
                    onClick={() => setIsSideModalActive(true)}
                  ></span>
                </div>
              </td>
              <td>
                <div className="flex-col">
                  <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <Modal
        active={isSideModalActive}
        aside
        onClose={() => setIsSideModalActive(false)}
      >
        <ModalHeader>Edit User</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-3">
            <CustomSelect className="my-5">
              <option>Privilege</option>
              <option>Admin</option>
              <option>User</option>
            </CustomSelect>
            <input
              class="form-control"
              id="input"
              placeholder="Username"
            ></input>
            <input class="form-control" id="input" placeholder="E-mail"></input>
            <input
              class="form-control"
              id="input"
              placeholder="Full Name"
            ></input>
            <div className="flex flex-col gap-y-5 mt-5">
              <Switch label="Active" defaultChecked />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-center">
            <Button
              className="uppercase"
              onClick={() => setIsSideModalActive(false)}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        active={isCenteredModalActive}
        centered
        onClose={() => setIsCenteredModalActive(false)}
      >
        <ModalHeader>New User</ModalHeader>
        <ModalBody>
          <div className="flex flex-col m-3 gap-3">
            <div className="flex flex-row gap-3">
              <Input id="username" placeholder="Username" />
              <Input id="password" placeholder="Password" />
            </div>
            <div className="flex flex-row gap-3">
              <Input id="email" placeholder="E-mail" />
              <Input id="name" placeholder="Full Name" />
            </div>
            <CustomSelect>
              <option>Privilege</option>
              <option>Admin</option>
              <option>User</option>
            </CustomSelect>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex ltr:ml-auto rtl:mr-auto">
            <Button
              color="secondary"
              className="uppercase"
              onClick={() => setIsCenteredModalActive(false)}
            >
              Close
            </Button>
            <Button
              className="ltr:ml-2 rtl:mr-2 uppercase"
              onClick={() => setIsCenteredModalActive(false)}
            >
              Save Changes
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Footer />
    </main>
  );
};

export default Accounts;
