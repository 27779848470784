const Footer = () => {
  return (
    <footer className="mt-auto">
      <div className="footer">
        <span className="uppercase">&copy; 2023 Perseverance Research Center</span>
      </div>
    </footer>
  );
};

export default Footer;
