const config = {
  darkMode: false,
  RTL: false,
  brandedMenu: false,
  menuType: "default",
  theme: "default",
  gray: "default",
  fonts: "default",
};

export default config;
