const files = [
  {
    id: 1,
    name: "Potato",
    thumbnail: null,
    type: null,
    size: 525,
    createdDateTime: "1631979705",
    updatedDateTime: "1645328968",
  },
  {
    id: 2,
    name: "Tomato",
    thumbnail: null,
    type: null,
    size: 735,
    createdDateTime: "1650404171",
    updatedDateTime: "1651391061",
  },
  {
    id: 3,
    name: "Pepper",
    thumbnail: null,
    type: null,
    size: 362,
    createdDateTime: "1649725555",
    updatedDateTime: "1639408626",
  },
  {
    id: 4,
    name: "Ingredients.mp3",
    thumbnail: null,
    type: "audio/mp3",
    size: 378,
    createdDateTime: "1658427925",
    updatedDateTime: "1657312397",
  },
  {
    id: 5,
    name: "Garlic.mp4",
    thumbnail: null,
    type: "video/mp4",
    size: 969,
    createdDateTime: "1650158725",
    updatedDateTime: "1650824920",
  },
  {
    id: 6,
    name: "Onion.pdf",
    thumbnail: null,
    type: "document/pdf",
    size: 435,
    createdDateTime: "1642823304",
    updatedDateTime: "1636433608",
  },
  {
    id: 7,
    name: "Potato.jpg",
    thumbnail: "potato.jpg",
    type: "image/jpg",
    size: 410,
    createdDateTime: "1659756670",
    updatedDateTime: "1655058865",
  },
  {
    id: 8,
    name: "Tomato.jpg",
    thumbnail: "tomato.jpg",
    type: "image/jpg",
    size: 276,
    createdDateTime: "1640076944",
    updatedDateTime: "1641951868",
  },
  {
    id: 9,
    name: "Pepper.jpg",
    thumbnail: "pepper.jpg",
    type: "image/jpg",
    size: 852,
    createdDateTime: "1655010548",
    updatedDateTime: "1630483517",
  },
  {
    id: 10,
    name: "Ingredients.jpg",
    thumbnail: "ingredients.jpg",
    type: "image/jpg",
    size: 538,
    createdDateTime: "1635580930",
    updatedDateTime: "1655662199",
  },
  {
    id: 11,
    name: "Garlic.jpg",
    thumbnail: "garlic.jpg",
    type: "image/jpg",
    size: 104,
    createdDateTime: "1651105915",
    updatedDateTime: "1653783049",
  },
  {
    id: 12,
    name: "Breakfast.jpg",
    thumbnail: "breakfast.jpg",
    type: "image/jpg",
    size: 296,
    createdDateTime: "1632515855",
    updatedDateTime: "1652253810",
  },
  {
    id: 13,
    name: "Onion.jpg",
    thumbnail: "onion.jpg",
    type: "image/jpg",
    size: 523,
    createdDateTime: "1653605654",
    updatedDateTime: "1645037229",
  },
  {
    id: 14,
    name: "Pepper Flower.jpg",
    thumbnail: "pepper-flower.jpg",
    type: "image/jpg",
    size: 345,
    createdDateTime: "1645811838",
    updatedDateTime: "1655894442",
  },
  {
    id: 15,
    name: "Potato.jpg",
    thumbnail: "potato.jpg",
    type: "image/jpg",
    size: 598,
    createdDateTime: "1630005280",
    updatedDateTime: "1632678752",
  },
  {
    id: 16,
    name: "Tomato.jpg",
    thumbnail: "tomato.jpg",
    type: "image/jpg",
    size: 541,
    createdDateTime: "1640911588",
    updatedDateTime: "1641440283",
  },
  {
    id: 17,
    name: "Pepper.jpg",
    thumbnail: "pepper.jpg",
    type: "image/jpg",
    size: 303,
    createdDateTime: "1656830169",
    updatedDateTime: "1649951908",
  },
  {
    id: 18,
    name: "Ingredients.jpg",
    thumbnail: "ingredients.jpg",
    type: "image/jpg",
    size: 126,
    createdDateTime: "1645777530",
    updatedDateTime: "1657580057",
  },
  {
    id: 19,
    name: "Garlic.jpg",
    thumbnail: "garlic.jpg",
    type: "image/jpg",
    size: 369,
    createdDateTime: "1637373203",
    updatedDateTime: "1634076026",
  },
  {
    id: 20,
    name: "Breakfast.jpg",
    thumbnail: "breakfast.jpg",
    type: "image/jpg",
    size: 655,
    createdDateTime: "1649887137",
    updatedDateTime: "1634363158",
  },
  {
    id: 21,
    name: "Onion.jpg",
    thumbnail: "onion.jpg",
    type: "image/jpg",
    size: 298,
    createdDateTime: "1648820498",
    updatedDateTime: "1643843067",
  },
  {
    id: 22,
    name: "Pepper Flower.jpg",
    thumbnail: "pepper-flower.jpg",
    type: "image/jpg",
    size: 913,
    createdDateTime: "1646008399",
    updatedDateTime: "1644294426",
  },
  {
    id: 23,
    name: "Potato.jpg",
    thumbnail: "potato.jpg",
    type: "image/jpg",
    size: 784,
    createdDateTime: "1632308888",
    updatedDateTime: "1640035719",
  },
  {
    id: 24,
    name: "Tomato.jpg",
    thumbnail: "tomato.jpg",
    type: "image/jpg",
    size: 441,
    createdDateTime: "1638244080",
    updatedDateTime: "1633552191",
  },
  {
    id: 25,
    name: "Pepper.jpg",
    thumbnail: "pepper.jpg",
    type: "image/jpg",
    size: 432,
    createdDateTime: "1652694522",
    updatedDateTime: "1630248121",
  },
  {
    id: 26,
    name: "Ingredients.jpg",
    thumbnail: "ingredients.jpg",
    type: "image/jpg",
    size: 278,
    createdDateTime: "1653721728",
    updatedDateTime: "1639039446",
  },
  {
    id: 27,
    name: "Garlic.jpg",
    thumbnail: "garlic.jpg",
    type: "image/jpg",
    size: 924,
    createdDateTime: "1655983270",
    updatedDateTime: "1653266703",
  },
  {
    id: 28,
    name: "Breakfast.jpg",
    thumbnail: "breakfast.jpg",
    type: "image/jpg",
    size: 289,
    createdDateTime: "1643987403",
    updatedDateTime: "1657154274",
  },
  {
    id: 29,
    name: "Onion.jpg",
    thumbnail: "onion.jpg",
    type: "image/jpg",
    size: 669,
    createdDateTime: "1650323327",
    updatedDateTime: "1656542822",
  },
  {
    id: 30,
    name: "Pepper Flower.jpg",
    thumbnail: "pepper-flower.jpg",
    type: "image/jpg",
    size: 891,
    createdDateTime: "1654005377",
    updatedDateTime: "1642949087",
  },
];

export default files;
