import { useState } from "react";
import CustomSelect from "../components/form/CustomSelect";
import data from "../data/data";




export default function Search({ patientsResults, studySelected, patientSelected, vertical = false, allowPatientDropdown = true }) {

    const [study, setStudy] = useState();
    const [patients, setPatients] = useState();

    const studies = data.map((study) => (
        <option key={study.id} value={study.id}>{study.name}</option>
    ))

    const handleStudyChange = (obj) => {
        const study = data.find(s => s.id == obj.target.value);

        
        
        if (study?.patients) {
            setPatients(study.patients.map((patient) => (
                <option key={patient.id} value={patient.id}>{patient.number}</option>
            )));
        }
        else {
            setPatients();
        }

        setStudy(study)
        if(patientSelected){
            patientSelected();
        }

        if (studySelected) {
            studySelected(study);
        }

        if (patientsResults) {
            patientsResults(study?.patients);
        }
    };

    const handlePatientChange = (obj) => {

        const patient = study?.patients.find(s => s.id == obj.target.value);

        if (patient && allowPatientDropdown && patientSelected) {
            patientSelected(patient);
        }
        else {
            patientSelected(null);
        }
    }
    const getComponent = () => {
        if (!vertical) {
            return <dl className="mb-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
                <div key="Studies" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt className="h3">Studies</dt>

                    <dd className="mt-5">
                        <CustomSelect
                            onChange={handleStudyChange}
                            value={study?.id || ''}>
                            <option key={''} value=''></option>
                            {studies}
                        </CustomSelect>
                    </dd>
                </div>
                {patients && allowPatientDropdown &&
                    <div key="Patients" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="h3">Patients</dt>

                        <dd className="mt-5">
                            <CustomSelect
                                onChange={handlePatientChange}>
                                <option key={''} value=''></option>
                                {patients}
                            </CustomSelect>
                        </dd>
                    </div>}
            </dl>
        }

        return <div>
            <CustomSelect className="m-2"
                onChange={handleStudyChange}
                value={study?.id || ''}>
                <option value={''}>Studies</option>
                {studies}
            </CustomSelect>
            <CustomSelect className="m-2" onChange={handlePatientChange}>
                <option key={''} value=''>Patients</option>
                {patients}
            </CustomSelect>
        </div>
    }

    return (<>
       {getComponent()}
    </>);
};
