import Footer from "partials/Footer";

import LineWithAnnotationChart from "components/charts/LineWithAnnotationChart";
import Area from "components/charts/Area";
import Badge from "components/Badge";
import Breadcrumb, {BreadcrumbItem} from "components/Breadcrumb";
import Button from "components/Button";
import CustomSelect from "components/form/CustomSelect";
import Input from "components/form/Input";
import Label from "components/form/Label";
import PolarArea from "components/charts/PolarArea";
import Textarea from "components/form/Textarea";

import DataChartJS from "data/chartjs";
import Doughnut from "components/charts/Doughnut";
import Dropdown from "components/Dropdown";
import data from "data/data";
import studies from "data/data";
import {useState, useEffect} from "react";


const Dashboard = () => {


    let patientsCount = 0;

    let patientsAddedThisWeek = 0;

    let aesCount = 0;
    let aesAddedThisWeek = 0;
    let aesAnswered = 0;
    let aesNewNotAnswered = 0;
    let aesNotNewNotAnswered = 0;

    let conmedCount = 0;
    let conmedsAddedThisWeek = 0;
    let conmedsAnswered = 0;
    let conmedsNewNotAnswered = 0;
    let conmedsNotNewNotAnswered = 0;

    for (var s = 0; s < studies.length; s++) {
        const study = studies[s];
        patientsCount += study.patients.length;

        for (var p = 0; p < study.patients.length; p++) {
            let patient = study.patients[p];

            if (patient.addedThisWeek) {
                patientsAddedThisWeek++;
            }

            if (patient.adverseEvents) {

                aesCount += patient.adverseEvents.length;

                for (var ae = 0; ae < patient.adverseEvents.length; ae++) {

                    const adverseEvent = patient.adverseEvents[ae];
                    const date1 = new Date(adverseEvent.startDate);
                    const date2 = new Date();
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays <= 7) {
                        aesAddedThisWeek++;
                    }

                    if (adverseEvent.answered) {
                        aesAnswered++;
                    } else if (adverseEvent.new) {
                        aesNewNotAnswered++;
                    } else {
                        aesNotNewNotAnswered++;
                    }

                }

                conmedCount += patient.conmeds.length;

                for (var cm = 0; cm < patient.conmeds.length; cm++) {

                    const conmed = patient.conmeds[cm];
                    const date1 = new Date(conmed.startDate);
                    const date2 = new Date();
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays <= 7) {
                        conmedsAddedThisWeek++;
                    }

                    if (conmed.answered) {
                        conmedsAnswered++;
                    } else if (conmed.new) {
                        conmedsNewNotAnswered++;
                    } else {
                        conmedsNotNewNotAnswered++;
                    }

                }

            }
        }
    }

    const [study, setStudy] = useState({
        name: 'All Studies',
        doughnutData: calcDo(),
        responseRate: calcRR(calcDo()),
    });

    function calcDo() {
        let graphSum = [0, 0];

        for (var s = 0; s < studies.length; s++) {
            let study = studies[s];

            for (var d = 0; d < study.doughnutData.length; d++) {
                graphSum[d] += study.doughnutData[d];
            }
        }
        return graphSum;
    }

    function calcRR(i) {
        return Math.round((1 - (i[1] / i[0])) * 100)
    }

    function handleDoughnutChange(id, e) {
        const sel = studies.find(s => s.id === id);
        const obj = {
            name: sel.name,
            doughnutData: sel.doughnutData,
            responseRate: calcRR(sel.doughnutData),
        }
        setStudy(obj)
    }

    return (
        <main className="workspace">
            {/* Breadcrumb */}
            <section className="breadcrumb">
                <h1>Dashboard</h1>
            </section>
            <div className="grid lg:grid-cols-4 gap-5">
                <div className="grid sm:grid-cols-4 col-span-3 gap-5">
                    <div
                        className="card px-4 py-8 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                        <div className="grid grid-cols-2 gap-0">
                            <span className="text-primary text-6xl leading-none la la-users self-center"></span>

                            <div>
                                <div className="text-primary mt-5 text-4xl leading-none">
                                    {patientsCount}
                                </div>
                                <p className="mt-2">Patients</p>
                            </div>

                            <p className="mt-2 col-span-2">
                                {patientsAddedThisWeek} patients added this week
                            </p>
                        </div>
                    </div>
                    <div
                        className={`card px-4 pt-8 pb-4 flex flex-col justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200 ${
                            aesNewNotAnswered > 0
                                ? "border-4 border-danger"
                                : aesNotNewNotAnswered > 0
                                    ? "border-4 border-warning"
                                    : ""
                        }`}
                    >
                        <div className="grid grid-cols-2 gap-0">
                            <span className="text-primary text-6xl leading-none la la-first-aid self-center"></span>

                            <div>
                                <div className="text-primary mt-5 text-4xl leading-none">
                                    {aesCount}
                                </div>
                                <p className="mt-2">Open AEs</p>
                            </div>

                            <p className="mt-2 col-span-2">
                                {aesAddedThisWeek} new AEs added this week
                            </p>
                        </div>
                        <div className="flex flex-col xl:flex-row mt-3 gap-2 xl:gap-5">
                            {!!aesAnswered && (
                                <div className="badge badge_outlined border-2 badge_success uppercase">
                                    {aesAnswered} Done
                                </div>
                            )}
                            {!!aesNewNotAnswered && (
                                <div className="badge badge_outlined border-2 badge_danger uppercase">
                                    {aesNewNotAnswered} New
                                </div>
                            )}
                            {!!aesNotNewNotAnswered && (
                                <div className="badge badge_outlined border-2 badge_warning uppercase">
                                    {aesNotNewNotAnswered} Other
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`card px-4 pt-8 pb-4 flex flex-col justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200 ${
                            conmedsNewNotAnswered > 0
                                ? "border-4 border-danger"
                                : conmedsNotNewNotAnswered > 0
                                    ? "border-4 border-warning"
                                    : ""
                        }`}
                    >
                        <div className="grid grid-cols-2 gap-0">
                            <span className="text-primary text-6xl leading-none la la-capsules self-center"></span>

                            <div>
                                <div className="text-primary mt-5 text-4xl leading-none">
                                    {conmedCount}
                                </div>
                                <p className="mt-2">Ongoing Medications</p>
                            </div>

                            <p className="mt-2 col-span-2">
                                {conmedsAddedThisWeek} new Conmeds added this week
                            </p>
                        </div>
                        <div className="flex flex-col xl:flex-row mt-3 gap-2 xl:gap-5">
                            {!!conmedsAnswered && (
                                <div className="badge badge_outlined border-2 badge_success uppercase">
                                    {conmedsAnswered} Done
                                </div>
                            )}
                            {!!conmedsNewNotAnswered && (
                                <div className="badge badge_outlined border-2 badge_danger uppercase">
                                    {conmedsNewNotAnswered} New
                                </div>
                            )}
                            {!!conmedsNotNewNotAnswered && (
                                <div className="badge badge_outlined border-2 badge_warning uppercase">
                                    {conmedsNotNewNotAnswered} Other
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="card px-4 py-8 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                        <div className="grid grid-cols-2 gap-0">
                            <span className="text-primary text-6xl leading-none la la-clipboard self-center"></span>

                            <div>
                                <div className="text-primary mt-5 text-4xl leading-none">
                                    {studies.length}
                                </div>
                                <p className="mt-2">Current Studies</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-8 col-span-4 gap-5">
                        <div className="card p-5 min-w-0 col-span-2">
                            <h3>Response Rate</h3>
                            <h5 className="align-middle">{study.name}</h5>
                            <div className="mt-5 min-w-0">
                                <Doughnut data={study.doughnutData}/>
                            </div>
                            <div className={`text-success flex justify-center text-4xl text-green-500 mt-5 ${
                                study.responseRate < 50
                                    ? "text-danger"
                                    : study.responseRate < 70
                                        ? "text-warning"
                                        : ""
                            }`}>
                                {study.responseRate + "%"}
                            </div>
                            <div>
                                <div className="flex mt-5 justify-center">
                                    <Dropdown
                                        content={
                                            <div className="dropdown-menu">
                                                {studies.map((st) => (
                                                    <a href="#no-link" key={st.id} value={st.id}
                                                       onClick={(e) => handleDoughnutChange(st.id, e)}>{st.name}</a>
                                                ))
                                                }
                                            </div>
                                        }
                                    >
                                        <Button className="uppercase">
                                            Select Study
                                            <span
                                                className="ltr:ml-3 rtl:mr-3 la la-caret-down text-xl leading-none"></span>
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        {/* Outstanding AEs */}
                        <div className="card p-5 col-span-3 flex-col">
                            <div className="flow-root">
                                <h3 className="float-left">Outstanding AEs</h3>
                                <a
                                    href="#no-link"
                                    className="btn btn_primary float-right -mt-2"
                                >
                                    Show all Posts
                                </a>
                            </div>
                            <table className="table table_list mt-3 w-full">
                                <thead>
                                <tr>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Study
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Patient
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Symptom
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Time
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>ABC-111</td>
                                    <td>001</td>
                                    <td>Nausea</td>
                                    <td>
                                        <Badge color="success" outlined className="uppercase">
                                            2 days
                                        </Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td>XYZ-333</td>
                                    <td>002</td>
                                    <td>Headache</td>
                                    <td>
                                        <Badge color="warning" outlined className="uppercase">
                                            12 days
                                        </Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DEF-222</td>
                                    <td>001</td>
                                    <td>Nausea</td>
                                    <td>
                                        <Badge color="danger" outlined className="uppercase">
                                            21 days
                                        </Badge>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card p-5 flex-col col-span-3 row-span-2">
                            <h3>Upcoming Appointments </h3>
                            <table className="table table_hoverable w-full mt-3">
                                <thead>
                                <tr>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Study
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Pt
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Date
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Visit
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>XYZ-333</td>
                                    <td>001</td>
                                    <td>01/20/22 3:30pm</td>
                                    <td>8</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-phone self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>ABC-111</td>
                                    <td>003</td>
                                    <td>01/24/22 11:30am</td>
                                    <td>5</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-briefcase-medical self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DEF-222</td>
                                    <td>001</td>
                                    <td>01/22/22 9:30am</td>
                                    <td>7</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-briefcase-medical self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>ABC-111</td>
                                    <td>017</td>
                                    <td>01/21/22 1:30pm</td>
                                    <td>8</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-phone self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DEF-222</td>
                                    <td>006</td>
                                    <td>01/22/22 9:30am</td>
                                    <td>5</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-home self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>XYZ-333</td>
                                    <td>016</td>
                                    <td>01/22/22 10:30am</td>
                                    <td>7</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-phone self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>ABC-111</td>
                                    <td>012</td>
                                    <td>01/22/22 2:30pm</td>
                                    <td>8</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-phone self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>XYZ-333</td>
                                    <td>011</td>
                                    <td>01/23/22 11:30am</td>
                                    <td>5</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-home self-center"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DEF-222</td>
                                    <td>010</td>
                                    <td>01/22/22 9:30am</td>
                                    <td>7</td>
                                    <td>
                                        <span
                                            className="text-primary text-3xl leading-none la la-home self-center"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="card p-5 col-span-5">
                            <h3>Recent Events</h3>
                            <table className="table table_hoverable w-full mt-3">
                                <thead>
                                <tr>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Studies
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Pt
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Description
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Date
                                    </th>
                                    <th className="ltr:text-left rtl:text-right uppercase">
                                        Time
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="flex-col">
                                            <p>ABC-111</p>
                                            <p>ABC-111</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>001</p>
                                            <p>001</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>Add AE of Headache</p>
                                            <p>
                                                Added medication of Ibuprofen, 400mg, twice per day
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>01/10/2022</p>
                                            <p>01/10/2022</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>16:10:17</p>
                                            <p>16:14:22</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="flex-col">
                                            <p>XYZ-333</p>
                                            <p>XYZ-333</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>017</p>
                                            <p>017</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>Add AE of Nausea</p>
                                            <p>Added medication of Tums, 2 tablets, as needed</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>01/11/2022</p>
                                            <p>01/11/2022</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-col">
                                            <p>08:42:32</p>
                                            <p>09:01:55</p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}


// ======== TEMPLATE ========
// const Dashboard = () => {
//   const {
//     visitors,
//     lineWithAnnotationTO,
//     lineWithAnnotationAO,
//     lineWithAnnotationPO,
//     lineWithAnnotationSO,
//   } = DataChartJS();


//   return (
//     <main className="workspace">
//       {/* Breadcrumb */}
//       <section className="breadcrumb">
//         <Breadcrumb title="Dashboard">
//           <BreadcrumbItem link="#no-link">Login</BreadcrumbItem>
//           <BreadcrumbItem>Dashboard</BreadcrumbItem>
//         </Breadcrumb>
//       </section>

//       <div className="grid lg:grid-cols-2 gap-5">
//         {/* Summaries */}
//         <div className="grid sm:grid-cols-3 gap-5">
//           <div className="card px-4 py-8 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
//             <div>
//               <span className="text-primary text-5xl leading-none la la-sun"></span>
//               <p className="mt-2">Published Posts</p>
//               <div className="text-primary mt-5 text-3xl leading-none">18</div>
//             </div>
//           </div>
//           <div className="card px-4 py-8 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
//             <div>
//               <span className="text-primary text-5xl leading-none la la-cloud"></span>
//               <p className="mt-2">Pending Posts</p>
//               <div className="text-primary mt-5 text-3xl leading-none">16</div>
//             </div>
//           </div>
//           <div className="card px-4 py-8 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
//             <div>
//               <span className="text-primary text-5xl leading-none la la-layer-group"></span>
//               <p className="mt-2">Categories</p>
//               <div className="text-primary mt-5 text-3xl leading-none">9</div>
//             </div>
//           </div>
//         </div>

//         {/* Lines */}
//         <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
//           <div className="card p-5">
//             <LineWithAnnotationChart data={lineWithAnnotationTO} />
//           </div>
//           <div className="card p-5">
//             <LineWithAnnotationChart data={lineWithAnnotationAO} />
//           </div>
//           <div className="card p-5">
//             <LineWithAnnotationChart data={lineWithAnnotationPO} />
//           </div>
//           <div className="card p-5">
//             <LineWithAnnotationChart data={lineWithAnnotationSO} />
//           </div>
//         </div>

//         {/* Visitors */}
//         <div className="card p-5 min-w-0">
//           <h3>Visitors</h3>
//           <div className="mt-5 min-w-0">
//             <Area data={visitors} withShadow />
//           </div>
//         </div>

//         {/* Recent Posts */}
//         <div className="card p-5 flex flex-col">
//           <h3>Recent Posts</h3>
//           <table className="table table_list mt-3 w-full">
//             <thead>
//               <tr>
//                 <th className="ltr:text-left rtl:text-right uppercase">
//                   Title
//                 </th>
//                 <th className="w-px uppercase">Views</th>
//                 <th className="w-px uppercase">Pulbished</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                 </td>
//                 <td className="text-center">100</td>
//                 <td className="text-center">
//                   <Badge color="secondary" outlined className="uppercase">
//                     Draft
//                   </Badge>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   Donec tempor lacus quis ex ullamcorper, ut cursus dui
//                   pellentesque.
//                 </td>
//                 <td className="text-center">150</td>
//                 <td className="text-center">
//                   <Badge color="success" outlined className="uppercase">
//                     Published
//                   </Badge>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   Quisque molestie velit sed elit finibus, nec gravida nunc
//                   finibus.
//                 </td>
//                 <td className="text-center">300</td>
//                 <td className="text-center">
//                   <Badge color="warning" outlined className="uppercase">
//                     Pending
//                   </Badge>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   Morbi nec nisl ac libero facilisis finibus vitae fringilla
//                   dolor.
//                 </td>
//                 <td className="text-center">120</td>
//                 <td className="text-center">
//                   <Badge color="success" outlined className="uppercase">
//                     Published
//                   </Badge>
//                 </td>
//               </tr>
//               <tr>
//                 <td>Donec suscipit libero in nibh fringilla hendrerit.</td>
//                 <td className="text-center">180</td>
//                 <td className="text-center">
//                   <Badge color="secondary" outlined className="uppercase">
//                     Draft
//                   </Badge>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//           <div className="mt-auto">
//             <a href="#no-link" className="btn btn_primary mt-5">
//               Show all Posts
//             </a>
//           </div>
//         </div>

//         {/* Categories */}
//         <div className="card p-5 flex flex-col min-w-0">
//           <h3>Categories</h3>
//           <div className="flex-auto mt-5 min-w-0">
//             <PolarArea withShadow />
//           </div>
//         </div>

//         {/* Quick Post */}
//         <div className="card p-5">
//           <h3>Quick Post</h3>
//           <form className="mt-5">
//             <div className="mb-5">
//               <Label className="block mb-2" htmlFor="title">
//                 Title
//               </Label>
//               <Input id="title" />
//             </div>
//             <div className="mb-5">
//               <Label className="block mb-2" htmlFor="content">
//                 Content
//               </Label>
//               <Textarea id="content" rows="4"></Textarea>
//             </div>
//             <div className="mb-5">
//               <Label className="block mb-2" htmlFor="category">
//                 Category
//               </Label>
//               <CustomSelect id="category">
//                 <option>Select</option>
//                 <option>Option</option>
//               </CustomSelect>
//             </div>
//             <div className="mt-10">
//               <Button className="uppercase">Publish</Button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </main>
//   );
// };

export default Dashboard;
