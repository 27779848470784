import {useState, useEffect} from "react";

import Footer from "partials/Footer";
import Modal, {ModalBody, ModalFooter, ModalHeader} from "components/Modal";
import Button from "components/Button";
import tippy from 'tippy.js';
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import Textarea from "components/form/Textarea";
import data from "../data/data";


import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Search from "partials/Search";


const Scheduler = () => {


    const [isSideModalActive, setIsSideModalActive] = useState(false);
    const [modalData, setModalData] = useState({});
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState();
    const [study, setStudy] = useState();

    const convertToCalendar = (pt, study, appt) => {

        let cal;
        if (appt.eventType == "Visit") {
            cal = {
                title: appt.time + ' ' + study.name + ':' + pt.number + ' ' + ' V' + appt.visitNumber,
                date: appt.date,
                backgroundColor: study.calendarColor,
                extendedProps: {
                    study: study,
                    pt: pt,
                    appt: appt
                }
            }
        } else {
            cal = {
                title: study.name + ': ' + pt.number + ': Visit Window for ' + ' V' + appt.visitNumber,
                start: appt.start,
                end: appt.end,
                backgroundColor: study.windowColor,
                extendedProps: {
                    study: study,
                    pt: pt,
                    appt: appt
                }
            }
        }


        return cal;
    }

    const convertToList = (pt, study, appt) => {
        

        let cal;
        if (appt.eventType == "Visit") {
            const formattedDate = new  Date(appt.date);

            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            
            
            cal = {
                eventType: appt.eventType,
                study: study.name,
                patient: pt.number,
                date: formattedDate.toLocaleDateString('en-us', options),
                visitNumber: appt.visitNumber,
                location: appt.location,
            }
        }
        if (appt.eventType == "Window") {
            const formattedStart = new  Date(appt.start);
            const formattedEnd = new  Date(appt.end);

            const options = { month: 'numeric', day: 'numeric' };
            cal = {
                eventType: appt.eventType,
                study: study.name,
                patient: pt.number,
                date: formattedStart.toLocaleDateString('en-us', options) + ' - ' + formattedEnd.toLocaleDateString('en-us', options),
                visitNumber: appt.visitNumber,
            }
        }

        return cal;
    }


    function getAppointments() {

        let aes = [];

        if (patient) {

            if (patient.appointments) {
                for (var d = 0; d < patient.appointments.length; d++) {
                    aes.push(convertToCalendar(patient, study, patient.appointments[d]));
                }
            }

            return aes;
        }

        if (study) {

            for (var p = 0; p < study.patients.length; p++) {

                let pt = study.patients[p];

                if (!pt.appointments) {
                    continue;
                }

                for (var d = 0; d < pt.appointments.length; d++) {
                    const ae = pt.appointments[d];

                    if (ae.eventType == "Visit") {
                        const cal = convertToCalendar(pt, study, ae);
                        aes.push(cal);
                    }
                }
            }

            return aes;
        }

        for (var s = 0; s < data.length; s++) {
            let studyLocal = data[s];

            for (var p = 0; p < studyLocal.patients.length; p++) {

                let pt = studyLocal.patients[p];

                if (!pt.appointments) {
                    continue;
                }

                for (var d = 0; d < pt.appointments.length; d++) {
                    const ae = pt.appointments[d];


                    if (ae.eventType == "Visit") {
                        const cal = convertToCalendar(pt, studyLocal, ae);
                        aes.push(cal);
                    }
                }
            }}

        return aes;
    }

    function getAppointmentList() {

        let aes = [];

        if (patient) {

            if (patient.appointments) {
                for (var d = 0; d < patient.appointments.length; d++) {
                    aes.push(convertToList(patient, study, patient.appointments[d]));
                }
            }

            return aes;
        }

        if (study) {

            for (var p = 0; p < study.patients.length; p++) {

                let pt = study.patients[p];

                if (!pt.appointments) {
                    continue;
                }

                for (var d = 0; d < pt.appointments.length; d++) {
                    const ae = pt.appointments[d];

                    const cal = convertToList(pt, study, ae);
                    aes.push(cal);
                }
            }
            return aes;
        }

        for (var s = 0; s < data.length; s++) {
            let studyLocal = data[s];

            for (var p = 0; p < studyLocal.patients.length; p++) {

                let pt = studyLocal.patients[p];

                if (!pt.appointments) {
                    continue;
                }

                for (var d = 0; d < pt.appointments.length; d++) {
                    const ae = pt.appointments[d];


                    if (ae.eventType == "Visit") {
                        const cal = convertToList(pt, studyLocal, ae);
                        aes.push(cal);
                        if (aes.length == 7) {
                            return aes;
                            break;
                        }
                    }
                }
            }
        }
        return aes;
    }

    function getTooltip(b) {
        const dateOptions = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'};

        let z = '';
        if (b.event.extendedProps && b.event.extendedProps.appt.eventType === 'Window') {
            z += b.event.extendedProps.study.name + ': ' + b.event.extendedProps.pt.number + ' V' + b.event.extendedProps.appt.visitNumber + '</br>';
            z += 'Start: ' + b.event.start.toLocaleDateString('en-us', dateOptions) + '</br>';
            z += 'End: ' + b.event.end.toLocaleDateString('en-us', dateOptions) + '</br>';
            z += 'Fasting: Yes' + '</br>';
            z += 'Study Partner: Yes' + '</br>';
            z += 'Comments:' + '</br>';
        } else {
            z += b.event.extendedProps.study.name + ': ' + b.event.extendedProps.pt.number + ' V' + b.event.extendedProps.appt.visitNumber + '</br>';
            z += b.event.start.toLocaleDateString('en-us', dateOptions) + ' ' + b.event.extendedProps.appt.time + '</br>';
            z += 'Fasting: Yes' + '</br>';
            z += 'Study Partner: Yes' + '</br>';
            z += 'Comments:' + '</br>';
        }
        return z;
    }

    function handleEventClicked(info) {
        const d = {
            time: info.event.extendedProps.appt.time,
            isFasting: true,
            studyPartner: true,
            witholdDose: false,
            location: info.event.extendedProps.appt.location
        }
        setModalData(d);
        setIsSideModalActive(true);
    }

    function mounted(info) {
        tippy(info.el, {
            content: getTooltip(info),
            placement: 'left',
            theme: 'light-border tooltip',
            offset: [0, 12],
            touch: ["hold", 500],
            animation: "scale",
            allowHTML: true
        })
    }
    
    function handleDateClicked() {
        const d = {
            time: '',
            isFasting: false,
            studyPartner: false,
            witholdDose: false,
            location: ''
        }
        setModalData(d);
        setIsSideModalActive(true);
    }

    return (

        <main className="workspace">
            <style>
                {`
        .fc-event-title {
          font-size: .8em;
      }
      `}
            </style>
            {/* Breadcrumb */}
            <section className="breadcrumb">
                <h1>Scheduler</h1>
            </section>

            <div className="grid lg:grid-cols-5 gap-5">
                <div className="grid sm:grid-cols-8 col-span-4 gap-5">
                    <div className="card p-5 flex-col col-span-3 row-span-1 h-52">
                        <h3 className="mt-1">Find Appointments </h3>
                        <div className="flex flex-col mt-5 justify-items-center">
                            <Search patientsResults={setPatients} studySelected={setStudy} patientSelected={setPatient}
                                    allowPatientDropdown={true} vertical={true}/>
                        </div>
                    </div>

                    <div className="card p-5 col-span-5 row-span-6 flex-col">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={getAppointments()}
                            eventClick={handleEventClicked}
                            dateClick={handleDateClicked}
                            eventDidMount={mounted}
                        />
                    </div>

                    <div className="card p-5 flex-col col-span-3 row-span-5">
                        <h3 className="mt-1">Upcoming Appointments </h3>

                        <table className="table table_list mt-3 w-full">
                            <thead>
                            <tr>
                                <th className="ltr:text-left rtl:text-right uppercase">
                                    Study
                                </th>
                                <th className="ltr:text-left rtl:text-right uppercase">Pt</th>
                                <th className="ltr:text-left rtl:text-right uppercase">
                                    Date
                                </th>
                                <th className="ltr:text-left rtl:text-right uppercase">
                                    Visit
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {getAppointmentList().map((ae) => {
                                switch (ae.eventType) {
                                    case 'Visit': return (
                                        <tr key={ae.id}>
                                            <td>{ae.study}</td>
                                            <td>{ae.patient}</td>
                                            <td>{ae.date}</td>
                                            <td>{ae.visitNumber}</td>
                                            <td>
                                                {ae.location == 'home' && (
                                                    <span
                                                        className="text-primary text-3xl leading-none la la-home self-center"></span>
                                                )}
                                                {ae.location == 'clinic' && (
                                                    <span
                                                        className="text-primary text-3xl leading-none la la-briefcase-medical self-center"></span>

                                                )}
                                                {ae.location == 'tele' && (
                                                    <span
                                                        className="text-primary text-3xl leading-none la la-phone self-center"></span>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                    case 'Window': return (
                                        <tr key={ae.id}>
                                            <td>{ae.study}</td>
                                            <td>{ae.patient}</td>
                                            <td>{ae.date}</td>
                                            <td>{ae.visitNumber}</td>
                                        </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                active={isSideModalActive}
                aside
                onClose={() => setIsSideModalActive(false)}
            >
                <ModalHeader>Appointment</ModalHeader>
                <ModalBody>
                    <div className="flex flex-col justify-center">
                        <CustomSelect value={modalData ? modalData.time : ''}>
                            <option>Time</option>
                            <option value="8am">8:00am</option>
                            <option value="830am">8:30am</option>
                            <option value="9am">9:00am</option>
                            <option value="930am">9:30am</option>
                            <option value="10am">10:00am</option>
                            <option value="1030am">10:30am</option>
                            <option value="11am">11:00am</option>
                            <option value="1130am">11:30am</option>
                            <option value="Noon">12:00pm</option>
                            <option value="1230pm">12:30pm</option>
                            <option value="1pm">1:00pm</option>
                            <option value="130pm">1:30pm</option>
                            <option value="2pm">2:00pm</option>
                            <option value="230pm">2:30pm</option>
                            <option value="3pm">3:00pm</option>
                            <option value="330pm">3:30pm</option>
                            <option value="4pm">4:00pm</option>
                            <option value="430pm">4:30pm</option>
                            <option value="5pm">5:00pm</option>
                        </CustomSelect>
                        <div className="mt-5">
                            <Checkbox className="m-2" checked={modalData.isFasting} label="Fasting"/>
                            <Checkbox className="m-2" checked={modalData.studyPartner} label="Study Partner"/>
                            <Checkbox className="m-2" checked={modalData.witholdDose} label="Withhold Dose"/>
                        </div>
                        <div className="mt-5">
                            <CustomSelect value={modalData.location}>
                                <option >Visit Type</option>
                                <option value="clinic">Clinic Visit</option>
                                <option value="tele">Telephone Call</option>
                                <option value="home">Home Visit</option>
                            </CustomSelect>
                        </div>
                        <div className="mt-5">
                            <h5>Comments</h5>
                            <Textarea rows="5"></Textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex justify-center">
                        <Button
                            className="uppercase"
                            onClick={() => setIsSideModalActive(false)}
                        >
                            Save
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Footer/>

        </main>

    );
};


export default Scheduler;
