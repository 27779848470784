const studies = [
  {
    id: 1,
    name: "ABC-111",
    startDate: "01/01/2023",
    endDate: "01/31/2023",
    chartData: [3, 4, 2, 1, 5, 0],
    doughnutData: [25, 3],
    calendarColor: "#047857",
    windowColor: "#4fa189",
    patients: [
      {
        id: 1,
        number: "001",
        phoneNumber: "111-111-1111",
        addedThisWeek: true,
        adverseEvents: [],
        lastResponse: "01/13/2023",
        conmeds: [
          {
            id: 1,
            name: "lisinopril",
            startDate: "01/01/2023",
            new: false,
            answered: false,
            daysOpen: 5,
          },
        ],
        recentEvents: [
          {
            description:
              "Discontinued script for sertraline per patient request",
            date: "11/20/2021",
            time: "09:31:17",
          },
          {
            description: "Added 20mg levothyroxine 1x daily",
            date: "09/20/2021",
            time: "08:17:12",
          },
        ],
        notes: [
          {
            user: "Albert Einstein",
            note: "Check patient blood pressure status",
            date: "10/18/2022",
            time: "18:25:15",
          },
          {
            user: "Isaac Newton",
            note: "Remind patient of new ICF next visit",
            date: "10/20/2022",
            time: "16:31:22",
          },
        ],
        appointments: [
          {
            date: "2023-01-17",
            time: "9am",
            eventType: "Visit",
            visitNumber: 5,
            location: "tele"
          },
          {
            date: "2023-01-24",
            time: "11am",
            eventType: "Visit",
            visitNumber: 7,
            location: "home"
          },
        ],
      },
      {
        id: 2,
        number: "002",
        phoneNumber: "222-222-2222",
        addedThisWeek: true,
        adverseEvents: [
          {
            id: 1,
            name: "Nausea",
            startDate: "01/10/2023",
            new: false,
            answered: false,
            daysOpen: 32,
          },
        ],
        lastResponse: "01/10/2023",
        conmeds: [
          {
            id: 2,
            name: "gabapentin",
            startDate: "01/09/2023",
            new: false,
            answered: false,
            daysOpen: 1,
          },
        ],
        recentEvents: [
          {
            description:
              "Reviewed recent lab report and discussed with patient",
            date: "07/09/2022",
            time: "02:01:21",
          },
        ],
        notes: [
          {
            user: "Marie Curie",
            note: "Order labs for patient",
            date: "11/26/2020",
            time: "17:36:09",
          },
          {
            user: "Albert Einstein",
            note: "Have patient pick up their socks",
            date: "12/28/2022",
            time: "12:18:21",
          },
        ],
        appointments: [
          {
            date: "2023-01-23",
            time: "1pm",
            eventType: "Visit",
            visitNumber: 5,
            location: "home"
            
          },
          {
            date: "2023-01-27",
            time: "Noon",
            eventType: "Visit",
            visitNumber: 6,
            location: "clinic"
          },
          {
            start: "2023-01-30",
            end: "2023-02-04",
            eventType: "Window",
            visitNumber: 7,
            location: "tele"
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "DEF-222",
    startDate: "01/01/2023",
    endDate: "01/31/2023",
    chartData: [2, 0, 1, 5, 4, 3],
    doughnutData: [25, 8],
    calendarColor: "#7c3aed",
    windowColor: "#a375f2",
    patients: [
      {
        id: 3,
        number: "001",
        phoneNumber: "333-333-3333",
        lastResponse: "01/01/2023",
        recentEvents: [
          {
            description: "Recommended 30 minutes light exercise daily",
            date: "06/05/2022",
            time: "10:16:07",
          },
          {
            description: "Referral for PT",
            date: "05/18/2022",
            time: "10:02:07",
          },
        ],
        notes: [
          {
            user: "Stephen Hawking",
            note: "Council patient on new medication",
            date: "10/27/2022",
            time: "10:18:03.",
          },
        ],
        appointments: [
          {
            date: "2023-01-19",
            time: "11am",
            eventType: "Visit",
            visitNumber: 4,
            location: "clinic"
          },
          {
            start: "2023-01-23",
            end: "2023-01-28",
            eventType: "Window",
            visitNumber: 5,
            location: "home"
          },
        ],
      },
      {
        id: 4,
        number: "002",
        phoneNumber: "444-444-4444",
        lastResponse: "01/16/2023",
        adverseEvents: [
          {
            id: 2,
            name: "Nausea",
            startDate: "01/01/2023",
            new: true,
            answered: false,
            daysOpen: 10,
          },
          {
            id: 5,
            name: "Headaches",
            startDate: "01/15/2023",
            new: true,
            answered: false,
            daysOpen: 2,
          },
        ],
        conmeds: [
          {
            id: 3,
            name: "omeprazole",
            startDate: "01/08/2023",
            new: true,
            answered: true,
            daysOpen: 8,
          },
        ],
        recentEvents: [
          {
            description: "Ordered X-ray of left leg",
            date: "04/03/2021",
            time: "10:28:17",
          },
        ],
        notes: [
          {
            user: "Marie Curie",
            note: "Order CT scan for patient",
            date: "08/12/2020",
            time: "10:15:01",
          },
          {
            user: "Albert Einstein",
            note: "Remind patient to schedule labs",
            date: "04/05/2021",
            time: "09:31:07",
          },
          {
            user: "Galileo Galilei",
            note: "Check tongue color of patient at next appointment",
            date: "04/28/2022",
            time: "05:22:10",
          },
        ],
        appointments: [
          {
            date: "2023-01-31",
            time: "2pm",
            eventType: "Visit",
            visitNumber: 3,
            location: "home"
          },
          {
            date: "2023-01-24",
            time: "9am",
            eventType: "Visit",
            visitNumber: 2,
            location: "clinic"
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "XYZ-333",
    startDate: "01/01/2023",
    endDate: "01/31/2023",
    chartData: [0, 1, 4, 3, 2, 5],
    doughnutData: [25, 5],
    calendarColor: "#dc2626",
    windowColor: "#e76767",
    patients: [
      {
        id: 5,
        number: "001",
        phoneNumber: "555-555-5555",
        lastResponse: "01/16/2023",
        recentEvents: {
          description: "Completed EKG",
          date: "06/08/2022",
          time: "16:02:16",
        },
        notes: [
          {
            user: "Isaac Newton",
            note: "Test patient hearing at next appointment",
            date: "06/12/2021",
            time: "18:25:09",
          },
        ],
        appointments: [
          {
            date: "2023-01-17",
            time: "10am",
            eventType: "Visit",
            visitNumber: 2,
            location: "tele"
          },
        ],
      },
      {
        id: 6,
        number: "002",
        phoneNumber: "666-666-6666",
        lastResponse: "01/12/2023",
        recentEvents: [
          {
            description:
              "Reviewed recent lab report and discussed with patient",
            date: "07/09/2022",
            time: "08:02:21",
          },
        ],
        notes: [
          {
            user: "Marie Curie",
            note: "Remind patient to track sugar levels",
            date: "06/20/2021",
            time: "09:07:02",
          },
        ],
        appointments: [
          {
            date: "2023-01-26",
            time: "3pm",
            eventType: "Visit",
            visitNumber: 5,
            location: "clinic"
          },
        ],
      },
    ],
  },
];

export default studies;