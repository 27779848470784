import Customizer from "partials/Customizer";
import MenuBar from "partials/MenuBar";
import TopBar from "partials/TopBar";

const Navigation = () => {
  return (
    <>
      <TopBar />
      <MenuBar />
      <Customizer />
    </>
  );
};

export default Navigation;
