import { useState } from "react";
import Line from "../components/charts/Line";
import Badge from "../components/Badge";
import InputGroup from "../components/form/InputGroup";
import Input from "../components/form/Input";
import Button from "../components/Button";
import Search from "../partials/Search";
import data from "data/data";
import { atRule } from "postcss";

export default function Studies() {

    const [patient, setPatient] = useState();
    const [studies, setStudies] = useState(data);

    const selectStudy = (study) => {

        setPatient();
        
        if (study) {
            setStudies([study]);
            
        }
        else {
            setStudies(data);
        }

    }

    const selectPatient = (patient) => {
        setPatient(patient);
    }

    const getGraphData = () => {
        let graphSum = [0, 0, 0, 0, 0, 0];

        for (var s = 0; s < studies.length; s++) {
            let study = studies[s];

            for (var d = 0; d < study.chartData.length; d++) {
                graphSum[d] += study.chartData[d];
            }
        }

        if (studies.length > 1) {
            for (var s = 0; s < studies.length; s++) {
                graphSum[s] /= studies.length;
            }
        }

        return graphSum;
    }

    const getAdverseEvents = () => {

        if (patient) {
            return patient.adverseEvents;
        }

        let aes = [];

        for (var s = 0; s < studies.length; s++) {
            let study = studies[s];

            for (var p = 0; p < study.patients.length; p++) {

                let pt = study.patients[p];

                if (!pt.adverseEvents) {
                    continue;
                }

                for (var d = 0; d < pt.adverseEvents.length; d++) {
                    const ae = pt.adverseEvents[d];

                    aes.push(ae);

                    if (aes.length == 2) {
                        break;
                    }
                }
            }
        }

        return aes;
    }

    const getConmeds = () => {

        if (patient) {
            return patient.conmeds;
        }

        let aes = [];

        for (var s = 0; s < studies.length; s++) {
            let study = studies[s];

            for (var p = 0; p < study.patients.length; p++) {

                let pt = study.patients[p];

                if (!pt.conmeds) {
                    continue;
                }

                for (var d = 0; d < pt.conmeds.length; d++) {
                    const ae = pt.conmeds[d];

                    aes.push(ae);

                    if (aes.length == 2) {
                        break;
                    }
                }
            }
        }

        return aes;
    }

    const getRecentEvents = () => {

        let aes = [];
        if (patient) {

            for (var d = 0; d < patient.recentEvents.length; d++) {
                const ae = patient.recentEvents[d];

                aes.push({ ptNumber: patient.number, ae });
            }
            return aes;
        }

        

        for (var s = 0; s < studies.length; s++) {
            let study = studies[s];

            for (var p = 0; p < study.patients.length; p++) {

                let pt = study.patients[p];

                if (!pt.recentEvents) {
                    continue;
                }

                const ptNumber = pt.number;

                for (var d = 0; d < pt.recentEvents.length; d++) {
                    const ae = pt.recentEvents[d];

                    aes.push({ ptNumber, ae });
                }
            }
        }

        return aes;
    }




    return (
      <main className="workspace">
        {/* Breadcrumb */}
        <section className="breadcrumb">
          <h1>Studies Patient</h1>
        </section>
        <Search patientSelected={selectPatient} studySelected={selectStudy} />

        <div className="grid lg:grid-cols-3 gap-5">
          {/* Compliance chart */}
          <div className="card p-5">
            <h3>Compliance</h3>
            <div className="mt-5 min-w-0">
              <Line data={getGraphData()} />
            </div>
          </div>

          {/* Open AEs/Conmeds table */}
          <div className="card p-5">
            <div className="flow-root">
              <h3 className="float-left">Open AEs/Conmeds</h3>
              <a
                href="#no-link"
                className="btn btn_primary float-right -mt-2 ml-5"
              >
                Show all Posts
              </a>
            </div>
            {getAdverseEvents()?.length > 0 && (
              <table className="table table_list mt-3 w-full">
                <thead>
                  <tr>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Adverse Event
                    </th>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Start Date
                    </th>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Days Open
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getAdverseEvents().map((ae) => (
                    <tr key={ae.id}>
                      <td>{ae.name}</td>
                      <td>{ae.startDate}</td>

                      <td>
                        {ae.daysOpen >= 21 && (
                          <Badge color="danger" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                        {ae.daysOpen <= 7 && (
                          <Badge color="success" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                        {ae.daysOpen > 7 && ae.daysOpen < 21 && (
                          <Badge color="warning" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {getConmeds()?.length > 0 && (
              <table className="table table_list mt-3 w-full">
                <thead>
                  <tr>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Medication
                    </th>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Start Date
                    </th>
                    <th className="ltr:text-left rtl:text-right uppercase">
                      Days Open
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getConmeds().map((ae) => (
                    <tr key={ae.id}>
                      <td>{ae.name}</td>
                      <td>{ae.startDate}</td>

                      <td>
                        {ae.daysOpen >= 21 && (
                          <Badge color="danger" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                        {ae.daysOpen <= 7 && (
                          <Badge color="success" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                        {ae.daysOpen > 7 && ae.daysOpen < 21 && (
                          <Badge color="warning" outlined className="uppercase">
                            {ae.daysOpen} days
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Recent Events Table */}
          <div className="card p-5">
            <h3>Recent Events</h3>
            <table className="table table_hoverable w-full mt-3">
              <thead>
                <tr>
                  <th className="ltr:text-left rtl:text-right uppercase">Pt</th>
                  <th className="ltr:text-left rtl:text-right uppercase">
                    Description
                  </th>
                  <th className="ltr:text-left rtl:text-right uppercase">
                    Date
                  </th>
                  <th className="ltr:text-left rtl:text-right uppercase">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {getRecentEvents()?.map((ae) => (
                  <tr key={ae.ptNumber + ae.ae.date}>
                    <td>{ae.ptNumber}</td>
                    <td>{ae.ae.description}</td>
                    <td>{ae.ae.date}</td>
                    <td>{ae.ae.time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Notes Table */}
          {patient?.notes && (
            <div className="card p-5 row-start-2 col-span-3">
              <h3>Patient Notes</h3>
              <div className="mt-3 h-[24rem] overflow-auto">
                <table className="table table_list w-full">
                  <thead>
                    <tr>
                      <th className="ltr:text-left rtl:text-right uppercase">
                        Note
                      </th>
                      <th className="ltr:text-left rtl:text-right uppercase">
                        User
                      </th>
                      <th className="ltr:text-left rtl:text-right uppercase"></th>
                      <th className="ltr:text-left rtl:text-right uppercase"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {patient.notes.map((note) => (
                      <tr key={note.note}>
                        <td>{note.note}</td>
                        <td>{note.user}</td>
                        <td>{note.time}</td>
                        <td>{note.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <InputGroup>
                  <Input className="input-group-item" placeholder="Notes" />
                  <Button className="input-group-item uppercase">Save</Button>
                </InputGroup>
              </div>
            </div>
          )}
        </div>
      </main>
    );
};