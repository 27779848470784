import { useState } from "react";

import Footer from "partials/Footer";
import Collapse, { Accordion, AccordionItem } from "components/Collapse";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/Modal";
import Button from "components/Button";
import CustomSelect from "components/form/CustomSelect";
import Input from "components/form/Input";
import Search from "partials/Search";





const Patients = () => {
  const [isCenteredModalActive, setIsCenteredModalActive] = useState(false);

  const [patients, setPatients] = useState();
  const [study, setStudy] = useState();


  return (
    <main className="workspace">
      {/* Breadcrumb */}
      <section className="breadcrumb">
        <h1>Patients</h1>
      </section>

      <Search patientsResults={setPatients} studySelected={setStudy} allowPatientDropdown={false} />

      <div className="grid grid-cols-4 overflow-auto">
        <div className="card p-5 col-span-3">
          {/* Accordion */}
          <div className="flow-root">
            <a href="#no-link" className="btn btn_primary float-right -mt-2" onClick={() => setIsCenteredModalActive(true)}>
              New Patient
            </a>
          </div>

          <table className="table table_list mt-3 w-full">
            <thead>
              <tr>
                <th className="ltr:text-left rtl:text-right uppercase">
                  Patient #
                </th>
                <th className="ltr:text-left rtl:text-right uppercase">
                  Phone Number
                </th>
                <th className="ltr:text-left rtl:text-right uppercase">
                  Last Response
                </th>
                <th className="ltr:text-left rtl:text-right uppercase">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {!patients && <tr key="nodata"><td><span>No data</span></td></tr>}
              {patients && patients.map((patient) =>
                <tr key={patient.id}>
                  <td>
                    <div className="flex-col">
                      <p>{patient.number}</p>

                    </div>
                  </td>
                  <td>
                    <div className="flex-col">
                      <p>{patient.phoneNumber}</p>
                    </div>
                  </td>
                  <td>
                    <div className="flex-col">
                      <p>{patient.lastResponse}</p>
                    </div>
                  </td>
                  <td>
                    <div className="flex-col">
                      <p><span className="badge badge_outlined badge_success">
                        Active
                      </span></p>
                    </div>
                  </td>
                  <td>
                    <div className="flex-col">
                      <span className="text-danger text-3xl leading-none la la-trash-alt self-center"></span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        active={isCenteredModalActive}
        centered
        onClose={() => setIsCenteredModalActive(false)}
      >
        <ModalHeader>New Patient</ModalHeader>
        <ModalBody>
          <div className="flex flex-col m-3 gap-3">
            <CustomSelect>
              <option>Study</option>
              <option>XYZ111</option>
              <option>XYZ222</option>
              <option>DEF333</option>
            </CustomSelect>
            <Input id="patientNumber" placeholder="Patient Number" />
            <Input id="phoneNumber" placeholder="Phone Number" />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex ltr:ml-auto rtl:mr-auto">
            <Button
              color="secondary"
              className="uppercase"
              onClick={() => setIsCenteredModalActive(false)}
            >
              Close
            </Button>
            <Button className="ltr:ml-2 rtl:mr-2 uppercase"
              onClick={() => setIsCenteredModalActive(false)}
            >
              Save Changes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Footer />
    </main>
  );
};

export default Patients;
